var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resource-item-wrap",class:_vm.itemValid},[_c('div',{staticClass:"resource-item"},[_c('img',{staticClass:"filetype",attrs:{"src":require(`@/assets/images/${_vm.data.res.fileType}.png`),"alt":(_vm.data.highs && _vm.data.highs.filename && _vm.data.highs.filename[0]) ||
          _vm.data.res.filename ||
          1}}),_c('div',{staticClass:"resource-info"},[(!_vm.subscribed)?_c('h1',{staticClass:"resource-title"},[_c('a',{attrs:{"href":"javascript:void(0);"},domProps:{"innerHTML":_vm._s(
            (_vm.data.highs && _vm.data.highs.filename && _vm.data.highs.filename[0]) ||
              _vm.data.res.filename ||
              1
          )},on:{"click":_vm.openLogin}})]):_vm._e(),(_vm.subscribed && _vm.userinfo && _vm.userinfo.expired)?_c('h1',{staticClass:"resource-title"},[_c('a',{attrs:{"href":"javascript:void(0);"},domProps:{"innerHTML":_vm._s(
            (_vm.data.highs && _vm.data.highs.filename && _vm.data.highs.filename[0]) ||
              _vm.data.res.filename ||
              1
          )},on:{"click":_vm.checkVip}})]):_vm._e(),(_vm.subscribed && _vm.userinfo && !_vm.userinfo.expired)?_c('h1',{staticClass:"resource-title"},[_c('a',{class:_vm.itemValid,attrs:{"target":"_blank","href":_vm.data.url
              ? _vm.data.url
              : `/#/main/detail/${_vm.data.res.id ||
                  _vm.data.res.Id}?keyword=${_vm.keyword}&type=${
                  _vm.data.res.type
                }&parent=${_vm.data.res.parent}`},domProps:{"innerHTML":_vm._s(
            (_vm.data.highs && _vm.data.highs.filename && _vm.data.highs.filename[0]) ||
              _vm.data.res.filename ||
              1
          )}})]):_vm._e(),(_vm.data.res.filelist && _vm.data.res.filelist.length > 0)?_c('div',{staticClass:"detail-wrap"},[_vm._l((_vm.data.res.filelist.slice(0, 5)),function(detailItem,detailIndex){return _c('p',{key:`detail${detailIndex}`,staticClass:"detail-item-wrap"},[_c('span',{staticClass:"detail-item-title",domProps:{"innerHTML":_vm._s(
              (_vm.data.highs &&
                _vm.data.highs['filelist.filename'] &&
                _vm.data.highs['filelist.filename'][detailIndex]) ||
                detailItem.filename
            )}}),(detailItem.isdir !== 1)?_c('span',[_vm._v(" - "+_vm._s(_vm._f("bytesToSize")(detailItem.size)))]):_vm._e()])}),(_vm.data.res.filelist.length > 5)?_c('p',{staticClass:"detail-item-wrap"},[_c('span',{},[_vm._v("......")])]):_vm._e()],2):_vm._e(),_c('div',{staticClass:"resource-meta"},[_c('span',{staticClass:"meta-item"},[_c('span',{staticClass:"label"},[_vm._v("文件大小")]),_c('span',{staticClass:"em"},[_vm._v(_vm._s(_vm._f("bytesToSize")(_vm.data.res.size)))])]),(_vm.data.res.type === 'aliyundrive')?_c('img',{staticClass:"tag",attrs:{"src":"https://res.yimiaopan.com/pan/icons/aliyundrive.png"}}):_vm._e(),(_vm.data.res.type === 'quark')?_c('img',{staticClass:"tag",attrs:{"src":"https://res.yimiaopan.com/pan/icons/quark.png"}}):_vm._e(),(_vm.data.res.type === 'baidu')?_c('img',{staticClass:"tag",attrs:{"src":"https://res.yimiaopan.com/pan/icons/baidu.png"}}):_vm._e(),(_vm.data.res.type === 'xunleipan')?_c('img',{staticClass:"tag",attrs:{"src":"https://res.yimiaopan.com/pan/icons/xunlei.png"}}):_vm._e(),(!_vm.status || _vm.status === -2)?_c('span',{staticClass:"warning em",staticStyle:{"padding-left":"6px"}},[_vm._v("资源状态检测中....")]):_vm._e(),(_vm.status === -1)?_c('span',{staticClass:"em",staticStyle:{"padding-left":"6px"}},[_vm._v("资源状态未知")]):_vm._e(),(_vm.status === 1)?_c('span',{staticClass:"success em",staticStyle:{"padding-left":"6px"}},[_vm._v("资源有效")]):_vm._e()])]),_c('div',{staticClass:"other-info"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.data.res.ctime.substr(0, 10)))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }